<template>
	<div id="schedule-calendar">

		<!-- Schedule modal -->
		<ScheduleModalTable
			v-if="collapseStep == 2"
			v-model="date"
			:daysCount="daysCount"
			:workTime="workTime"
			:slotCollection="slotCollection"
			:isModalOpen="isModalOpen"
			:closeHandler="closeTableModal"
		/>


		<!-- Current month name -->

		<MonthBar v-model="daysCount"
			:months="months"
			:currentMonthIndex="currentMonth"
			@on-select-date="selectToday"/>

		<!-- Week header -->

		<WeekBar :collapseStep="collapseStep"/>

		<!-- Calendar -->

		<CalendarCollapseBehavior ref="collapse-calendar"
			v-model="collapseStep" 
			:collapseMods="collapseMods"
			@on-collapse-move="collapseMoveHandler"
			@on-collapse-end="collapseEndHandler">
				<!-- <SwipeableWeek ref="week-calendar" v-model="date" v-if="week"
 					v-show="collapseStep == 0" :week="week"
					:slotCollection="slotCollection"
					:selector="selectDateHandler"
					:checkCurrentMonth="isCurrentMonth"
					:collapseStep="collapseStep"
					:daysCount="daysCount"
					@on-swipe-right="swipeRight"
					@on-swipe-left="swipeLeft"
				/> -->
				<BCarouselListMod without-buttons
					ref="week-calendar"
					v-show="collapseStep == 0"
					v-model="currentWeek"
					:arrow="false"
					carouselActivity="swipe-calendar"
					:has-drag="calendarIsScrollable"
					:data="weeks" :items-to-show="1"
					@input="swipeHandler">
					<template #item="week"> 
						<Week v-model="date"
							:week="week.list"
							:daysCount="daysCount"
							:disableSelection="collapseStep != 0"
							:collapseStep="collapseStep"
						/>
					</template>
				</BCarouselListMod>
				<BCarouselListMod without-buttons
					ref="month-calendar"
					v-show="collapseStep == 1 || collapseStep == 2"
					v-model="currentMonth"
					:arrow="false"
					carouselActivity="swipe-calendar"
					:has-drag="calendarIsScrollable"
					:data="months" :items-to-show="1"
					@input="swipeHandler">
					<template #item="month"> 
						<Month v-model="date"
							:month="month.list"
							:daysCount="daysCount"
							:collapseStep="collapseStep"
							:disableSelection="collapseStep == 0"
						/>
					</template>
				</BCarouselListMod>
		</CalendarCollapseBehavior>
	</div>
</template>

<script>
import { cssVh, cssVw, refreshCssUnits } from '../../../scripts/cssUnits';
import BCarouselListMod from '../../BCarouselListMod.vue'
import CalendarWorkTime from '../../../scripts/calendarWorkTime';
import SlotCollection from '../../../scripts/slotCollection';
import ScheduleModalTable from '../ScheduleModalTable.vue';
import Month from './Month.vue'; 
import MonthBar from './MonthBar.vue';
import Week from './Week.vue';
import WeekBar from './WeekBar.vue';
import CalendarCollapseBehavior from './CalendarCollapseBehavior.vue';
import YYYYMMDD, { weekStart, isEqualDate, weekEnd, nextDate, smartDateSelect } from '../../../scripts/date';

const collapseMods = {
	0: {height: 62},
	1: {height: 300},
}

export default {
	name: 'Calendar',
	components: {
		Month,
		Week,
		ScheduleModalTable,
		BCarouselListMod,
		CalendarCollapseBehavior,
		MonthBar,
		WeekBar,
	},
	emits: [
		'on-swipe', 
		'on-select-date', 
	],
	model: {
		prop: 'days',
		event: 'update:days'
	},
	props: {
		months: {
			type: Array,
			default: () => [],
		},
		selectedDate: {
			type: Date,
			required: true,
		},
		workTime: {
			type: CalendarWorkTime,
			required: true,
		},
		days: {
			type: Number,
			required: true
		}
	},

	data() {
		return {
			currentMonth: 0,
			currentWeek: 0,
			oldCurrentWeek: 0,

			initialWidth: 0,

			week: null,

			collapseStep: null,
			isModalOpen: false,
			calendarIsVisible: true,
			calendarIsScrollable: true,
		};
	},

	created() {
		const cachedCollapseStep = localStorage.getItem('calendar-collapse-step')
		this.collapseStep = cachedCollapseStep != null ?
			Number(cachedCollapseStep) : 1

		if (this.collapseStep == 0) {
			this.makeWeekCalendar()
		}
	},

	beforeMount(){
		this.setCarouselPosition()
	},

	mounted() {
		// TODO : change the height of the component depends on the current step
		this.withoutTransition(() => {})
	},

	computed: {
		date: {
			get(){
				return this.selectedDate
			},
			set(newDate){
				if (this.collapseStep == 2)
					this.openTableModal()
				this.$emit('on-select-date', newDate)
			}
		},
		ceilSize(){
			return this.$store.getters.tableCeilSize
		},

		slotCollection(){
			return this.$store.getters.calendarSlotCollection
		},

		weeks(){
			const weeks = []
			this.months.forEach(month => {
				if (!month)
					return 
				const monthWeeks = month.getWeeks()
					.filter(week => !weeks.find(w => isEqualDate(w.from, week.from)))
				weeks.push(...monthWeeks)
			})
			return weeks
		},
		collapseMods(){
			const mods = collapseMods
			mods[2] = {height : cssVh*100 - 82 - 27 - 30 - 21}

			return mods
		},
		daysCount : {
			get(){
				return this.days
			},
			set(value){
				this.$emit('update:days', value)
			}
		}
	},

	watch: {
		weeks(){
			const dateRangeEnd = nextDate(this.date, this.daysCount - 1)
			this.currentWeek = this.getWeekIndexByDate(dateRangeEnd)
			this.oldCurrentWeek = this.currentWeek

			let currentMonthIndex = this.getMonthIndexByWeek(this.weeks[this.currentWeek])
			const month = this.months[currentMonthIndex]

			const monthDate = month.firstDate
			if (monthDate.getMonth() != dateRangeEnd.getMonth()) {
				currentMonthIndex += dateRangeEnd.getMonth() - monthDate.getMonth() 
			}
			this.withoutTransition(() => {
				this.currentMonth = currentMonthIndex
			})
		},
	},

	methods: {
		setCarouselPosition(){
			const dateRangeEnd = nextDate(this.date, this.daysCount - 1)
			this.currentWeek = this.getWeekIndexByDate(dateRangeEnd)
			this.oldCurrentWeek = this.currentWeek
			this.currentMonth = this.getMonthIndexByDate(dateRangeEnd)
		},
		openTableModal(){
			let staticMenu = document.getElementById('static__menu')
			staticMenu.style["zIndex"] = 0

			let header = document.getElementById('header')
			header.style["zIndex"] = 0

			this.isModalOpen = true
		},
		closeTableModal(){
			let staticMenu = document.getElementById('static__menu')
			staticMenu.style["zIndex"] = null

			let header = document.getElementById('header')
			header.style["zIndex"] = null

			this.isModalOpen = false
		},
		selectToday(){
			this.date = smartDateSelect(new Date(), this.daysCount)
			this.$nextTick().then(() => {
				const dateRangeEnd = nextDate(this.date, this.daysCount - 1)
				const newDateWeekIndex = this.getWeekIndexByDate(dateRangeEnd)
				if (newDateWeekIndex == -1)
					return ;
				const newDateMonthIndex = this.getMonthIndexByDate(dateRangeEnd)
				if (newDateMonthIndex == -1 || newDateMonthIndex == null)
					return ;
				if (newDateWeekIndex != this.currentWeek) {
					this.currentWeek = newDateWeekIndex
					this.oldCurrentWeek = newDateWeekIndex
				}
				if (newDateMonthIndex != this.monthIndex) {
					this.currentMonth = newDateMonthIndex
				}
				this.$emit('on-swipe', newDateMonthIndex)
			})
		},

		swipeHandler(newValue){
			if (this.collapseStep == 0)
				this.swipeWeekHandler(newValue)
			else if (this.collapseStep == 1 || this.collapseStep == 2)
				this.swipeMonthHandler(newValue)
		},

		swipeWeekHandler(newActiveItemIndex){
			const calendar = this.$refs['week-calendar']

			let weekIndex = newActiveItemIndex
			const monthIndex = this.getMonthIndexByWeek(this.weeks[weekIndex])

			this.$emit('on-swipe', monthIndex)
			if (monthIndex == 0) {
				
				this.withoutTransition(() => {
					this.currentWeek += 4
					this.oldCurrentWeek = this.currentWeek
					weekIndex += 4
					calendar.scrollIndex = weekIndex
				})
			}

			if (monthIndex != 0 && this.currentMonth != monthIndex){
				this.currentMonth = monthIndex
			}	
			if (weekIndex != 0 && weekIndex != calendar.scrollIndex)
				calendar.scrollIndex = weekIndex
			const oldWeek = this.weeks[this.oldCurrentWeek]
			const currentWeek = this.weeks[weekIndex]
			const currentWeekStart = currentWeek.from

			let newDate
			if (oldWeek.includes(this.date)) {
				newDate = this.currentWeek > this.oldCurrentWeek ?
					nextDate(this.date, 7) : nextDate(this.date, -7)
			} else if (!currentWeek.includes(this.date)) {
				newDate = currentWeekStart
			} else {
				return
			}

			this.oldCurrentWeek = this.currentWeek
			this.date = newDate
		},

		swipeMonthHandler(newActiveItemIndex){
			// const calendar = this.$refs['month-calendar']
			const monthIndex = newActiveItemIndex
			this.$emit('on-swipe', monthIndex)

			this.setDateAsMonthStart(monthIndex)

		},	

		setDateAsMonthStart(monthIndex, enableSmartSelect=true){
			if (this.collapseStep == 2)
				return
			const firstDate = this.months[monthIndex].firstDate
			this.date = enableSmartSelect ?
				smartDateSelect(firstDate, this.daysCount) :
				firstDate
		},

		withoutTransition(action) {
			const calendar = this.collapseStep == 0 ?
				this.$refs['week-calendar'] :
				this.$refs['month-calendar']
			

			if (!calendar)
				return action()

			
			let carouselSlides = calendar.$el.querySelector('.carousel-slides');
			carouselSlides.style.transition = 'none';
			action()
			const currentValue = this.collapseStep == 0?
				this.currentWeek : this.currentMonth
			carouselSlides.style.transform = `translateX(-${100*cssVw*currentValue}px)`
			setTimeout(() => carouselSlides.style.transition = null, 0);
		},
		isCurrentMonth(date){
			const month = this.months[this.currentMonth]
			return month.includes(date)
		},
		getMonthIndexByWeek(week){
			return this.months.findIndex(month => month.includes(week.from))
		},
		getMonthIndexByDate(date){
			return this.months.findIndex(month => month.includes(date))
		},
		getWeekIndexByDate(date){
			date.setHours(0, 0, 0, 0)
			return this.weeks.findIndex(week => week.includes(date))
		},
		
		collapseMoveHandler(deltaY){

			let fullShortDifference = ( this.collapseMods[2].height - collapseMods[1].height) * 0.3
			let slotTitles = Array.from(document.querySelectorAll('.schedule-date__item-title'))


			slotTitles.forEach(title => {
				// Increase titles opacity
				if (this.collapseStep == 1 && deltaY > 0) {
					title.style.display = 'block'
					title.style.opacity = 1 - (1 - (deltaY / fullShortDifference) + 0.6) 
				} 
				// Decrease titles opacity
				if (this.collapseStep == 2 && deltaY < 0) { 
					title.style.opacity = 1 + (deltaY / fullShortDifference) + 0.35
				}
			})	
		},

		collapseEndHandler(newCollapseStep){

			if (newCollapseStep != this.collapseStep) {
				this.onUpdateCollapseStep(newCollapseStep)
			}

			// If 'full screen' mode is disabled hide the slot card titles
			// Overwise show them
			let slotTitles = Array.from(document.querySelectorAll('.schedule-date__item-title'))
			slotTitles.forEach(title => {
				if (this.collapseStep == 2) {
					title.style.display = 'block'
					title.style.opacity = 1
				} else {
					title.style.display = 'none'
				}
			})
		},

		makeWeekCalendar(){
			let weekDate = weekStart(this.selectedDate)
			// if (this.isCurrentMonth(this.selectedDate)){
			// 	weekDate = weekStart(this.selectedDate)
			// } else {
			// 	weekDate = weekStart(this.months[this.currentMonth].firstDate)
			// }
			this.currentWeek = this.getWeekIndexByDate(weekDate)
			this.oldCurrentWeek = this.currentWeek
		},
		// makeMonthShortCalendar(){
		// },
		// makeMonthFullCalendar(){
		// },

		onUpdateCollapseStep(newCollapseStep){

			// Save to the cache the current collapse step
			localStorage.setItem('calendar-collapse-step', newCollapseStep)

			// Update calendar
			this.$nextTick(() => {
				this.withoutTransition(() => {
					if (newCollapseStep == 0){
						this.makeWeekCalendar()
					} else if (newCollapseStep == 1) {
						// this.makeMonthShortCalendar()
					} else if (newCollapseStep == 2) {
						// this.makeMonthFullCalendar()
					}
				})
			})
		},
	},
};
</script>

<style>
.carousel-list.has-shadow{
	box-shadow: none;
}
.carousel-list{
	height: inherit;
}
</style>

<style scoped>

#schedule-calendar{
	height: fit-content;
	position: relative;
	width: 100%;
	padding-top: 10px;
	background: white;
	position: relative;
    z-index: 2;
}


.open-calendar-animation{
	animation: open-calendar 0.3s ease-in-out;
}

.month-padding{
	padding: 0 15px 10px 0;
	height:	inherit;
}

.close-calendar-animation{
	animation: close-calendar 0.3s ease-in-out;
}

@keyframes open-calendar {
	0% {
		max-height: 54px;
	}
	100% {
		max-height: 336px;
	}
}

@keyframes close-calendar {
	0% {
		max-height: 336px;
	}
	100% {
		max-height: 54px;
	}
}



.user-schedule__header-toggle{
	position: absolute;
	right: 10px;
	top: 10px;
	height: 30px;
	width: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 2;
}

.button-container{
	height: 24px;
	transition: transform 0.3s;
}
.calendar-header__varnish{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 54px;
}
.button-container.opened{
	transform: rotate(180deg);
}



#collapse-cover{
	touch-action: none;
	width: 100%;
	min-height: 62px;
	max-height: calc(100dvh - 32px - 10px - 27px - 30px - 50px  );
	overflow: hidden;
	border-bottom: 1.5px solid rgb(232, 232, 232);
}
</style>