<template>
	<div id="create-slot"
	@dragstart="() => false"
	v-bind:style="{
		top			: `${posY}px`,
		left 		: `${posX}px`,
		height		: `${cellHeight}px`,
		width		: `${columnWidth}px`,
	}"
	v-show="dragging"
	v-bind:class="{
		invalid: timeIsWrong,
		draggable: dragging,
	}">
		<div class="current-time-line" 
		v-bind:class="{
			invalid: timeIsWrong
		}"
		v-bind:style="{
			left: `${-posX}px`,
			right: `${columnWidth}px`,
		}">
			<div class="nearest-time" v-if="nearestPlace">
				Free time: {{ neatestPlaceTime }}
			</div>
			<div class="time-string">{{ time }}</div>
		</div>
	</div>
</template>

<script>
import { globalBus } from '../../GlobalEventBus';
import Calendar from '../../scripts/calendar';
import CalendarMonth from '../../scripts/calendarMonth';
import CalendarWorkTime from '../../scripts/calendarWorkTime';
import { isEqualDate, isEqualTime, pair } from '../../scripts/date';
import { makeDraggable, makeUndraggable } from '../../scripts/draggable';
import { scrollByCondition } from '../../scripts/scroll';
import { eventToCoordinates } from '../../scripts/slotCardPosition';
import TimePeriod from '../../scripts/timePeriod';
import eventBroker from '../calendar-v2/eventBus';
import { bus } from './EventBus';

const CONFIG = {
	"movement-step" : 10,
}

const SCROLL_STEPS = 3

export default {
	name: 'ScheduleCreateSlot',

	emits: [
		'on-drag-start',
		'on-drag-move',
		'on-drag-end',
	],

	props: {
		moveEvent: [PointerEvent, MouseEvent],
		selectedDate: {
			type: Date,
			required: true,
		},
		workTime: {
			type: CalendarWorkTime,
			required: true,
		},
		cellHeight: {
			type: Number,
			default: 54, 
		},
		columnCount: {
			type: Number,
			default: 1,
		},
		isDeletePreparation: {
			type: Boolean,
			default: false,
		},
		calendar: {
			type: Calendar,
			required: true,
		},
		currentMonth: {
			type: CalendarMonth,
			required: true,
		}
	},
	data() {
		return {
			posX: 0,
			posY: 0,

			timeIsWrong: false,

			slotContainer: null,
			slotContainerSizes: null,

			scheduleTableHeaderHeight: 0,
			dragging: null,

			scrollInterval: null,
			scrollSpeed: 0,

			insertIndex: null,
			currentMinute: 0,
			currentHour: 0,
			columnsX: null,
			futureColumn: null,
			columnWidth: null,

			lastInvalidTimeDate: null,
			nearestPlace: null,
		};
	},

	computed: {
		time(){
			return `${this.currentHour}:${pair(this.currentMinute)}`
		},
		scrollStep(){
			return this.cellHeight / SCROLL_STEPS
		},
		slotCollection(){
			return this.calendar.slotCollection
		},
		neatestPlaceTime(){
			if (!this.nearestPlace)
				return null
			const nearestPlaceDate = this.nearestPlace.start.date
			return `${pair(nearestPlaceDate.getHours())}:${pair(nearestPlaceDate.getMinutes())}`
		},
		monthDateRange(){
			return this.currentMonth.range()
		}
	},

	mounted() {
		if (window.navigator.vibrate)
			window.navigator.vibrate(65)
		makeDraggable(this.$el, {
			start: this.handleStart,
			move: this.handleMove,
			end: this.handleEnd,
		}, {enablePointerEvents: true})
	},

	beforeDestroy() {
		clearInterval(this.scrollRepeat)
		makeUndraggable(this.$el, {
			start: this.handleStart,
			move: this.handleMove,
			end: this.handleEnd,
		}, {enablePointerEvents: true})
	},

	methods: {
		pair: pair,

		getCurrentSlotContainer() {
			const currentCalendar = document.querySelector('.calendar-v2')
			if (!currentCalendar)
				return null
			this.scheduleTableHeaderHeight = 0
			return currentCalendar.querySelector(".calendar-v2-table")
		},

		// getCurrentTableFrame(){
		// 	let scheduleTable = document.getElementById("schedule-table")
		// 	let currentCarouselSlide = scheduleTable.querySelector(".carousel-slide.is-active")
		// 	if (!currentCarouselSlide)
		// 		return null
			
		// 	return currentCarouselSlide.querySelector(".schedule-table-frame")
		// },

		handleStart(event){
			this.slotContainer = this.getCurrentSlotContainer()
			if (!this.slotContainer)
				return 

			let slotContainerRect = this.slotContainer.getBoundingClientRect()
			this.columnWidth = Math.min((slotContainerRect.width - 46) / this.columnCount, 200)

			let sizes = {}
			sizes["height"] = Math.round(slotContainerRect.height - this.cellHeight - this.scheduleTableHeaderHeight)
			sizes["left"] = 32
			sizes["right"] = slotContainerRect.width  - 14 - this.columnWidth
			sizes["scroll-area"] = Math.round(this.slotContainer.scrollHeight)
			this.slotContainerSizes = sizes

			// Init start position of a drag and drop
			let {x, y} = eventToCoordinates(event);

			this.columnsX = Array.from(this.slotContainer.querySelectorAll(".calendar-column"))
				.map((column) => column.getBoundingClientRect().left)
			console.log(this.columnsX)
			this.posX = x - this.columnWidth / 2
			this.posY = y - slotContainerRect.top - this.cellHeight / 2

			// Init start point 

			this.dragging = {dx: this.posX - x, dy: this.posY - y}
			this.$emit('on-drag-start')

		},

		handleMove(event){
			if (!this.dragging)
				return
			let {x, y} = eventToCoordinates(event);

			let maxX = this.slotContainerSizes["right"]
			let minX = this.slotContainerSizes["left"]
			let maxY = this.slotContainerSizes["height"]+56
			let minY = this.scheduleTableHeaderHeight + 12

			let posX = Math.round(Math.max(minX, Math.min(x + this.dragging.dx, maxX)))
      		let posY = Math.round(Math.max(minY, Math.min(y + this.dragging.dy, maxY)))
			let isMovingDown = posY > this.posY
			this.posY = posY
			this.posX = posX
			
			this.futureColumn = this.calcFutureColumn(this.posX)
			this.setTimeForPosition(this.posY - 16.5, this.futureColumn)
			
			this.scrollContainer(posY, isMovingDown)
			
			if (!this.scrollInterval) {
				this.sendMoveEvent(posX, posY)
			}
			// this.handlePutNewSlot()
		},

		sendMoveEvent(posX, posY){
			this.$emit('on-drag-move', {posX, posY})
			if (this.slotCollection && this.timeIsWrong) {
				const originDate = this.futureColumn
				const date = new Date(originDate)
				date.setHours(this.currentHour, this.currentMinute)
				const period = new TimePeriod(date, -1, 60)
				this.nearestPlace = this.slotCollection.nearestFreePlace(period)
			} else {
				this.nearestPlace = null
			}
		},

		calcFutureColumn(posX){

			if (this.columnsX && this.columnsX.length == 1)
				return this.selectedDate

			const closestColumn = this.columnsX.map((columnX, index) => {
				return {
					index: index,
					diff: Math.abs(columnX - posX)
				}
			}).sort((a, b) => a.diff - b.diff)[0]
			const closestColumnDate = this.monthDateRange[closestColumn.index]

			this.colorizeFutureColumn(closestColumnDate)
			return closestColumnDate
		},

		colorizeFutureColumn(futureColumn){

			if (this.columnsX && this.columnsX.length == 1)
				return

			const columnTitles = Array.from(this.slotContainer.querySelectorAll('.calendar-date-title__item'))
			if (!futureColumn) {
				columnTitles.forEach((titleElem) => {
					titleElem.classList.remove('selected')
				})
				return
			}

			columnTitles.forEach((titleElem) => {
				titleElem.classList.remove('selected')
			})

			const columnDate = columnTitles.find((titleElem) => {
				const id = futureColumn.toLocaleDateString()
				return titleElem.id == `calendar-date-title__item-${id}`
			})
			if (!columnDate)
				return
			columnDate.classList.add('selected')
		},

		// handlePutNewSlot(){
		// 	let slotContainer = this.getCurrentSlotContainer()
		// 	let slot = slotContainer.querySelector(".slot")
		// 	let supportMessage = this.getCurrentTableFrame().querySelector(".message.support")
		// 	if (!slot && !supportMessage) {
		// 		return this.showSupportMessage()
		// 	}
		// 	if (!slot) {
		// 		return
		// 	}

		// 	this.handleInsertNewSlot()			
		// },

		showSupportMessage(){
			let tableFrameIndex=this.selectedDate.toLocaleDateString()
			bus.$emit(`table-frame-${tableFrameIndex}`, 'show-support-message', true)
		},

		checkValidTime(futureColumnDate, hour, minute){

			const dateIsPast = () => {
				const closestCreateTime = new Date()
				const current = new Date(futureColumnDate)
				current.setHours(hour, minute, 0, 0)
				return closestCreateTime > current	
			}

			const dateIsIntersect = () => {
				const newSlotTime = new Date(futureColumnDate)
				newSlotTime.setHours(hour, minute, 0, 0)

				return this.slotCollection.isBusyTime({
					date: newSlotTime,
					duration: 60,
				})
			}

			const timeChecks = [
				{name: 'is-past', check: dateIsPast, disabled: this.workTime.isAllowPastTime},
				{name: 'is-intersect', check: dateIsIntersect}
			]

			const invalidCheck = timeChecks.find(check => {
				if (check.disabled)
					return false
				return check.check()
			})
			if (invalidCheck) {
				const date = new Date(futureColumnDate)
				date.setHours(hour, minute, 0, 0)
				const period = new TimePeriod(date, -1, 60)

				this.onInvalidTime({
					reason: invalidCheck.name,
					period: period,
				})
			} else if (this.timeIsWrong) {
				this.onValidTime()
			}

			this.timeIsWrong = !!invalidCheck
		},

		setTimeForPosition(posY, futureColumnDate) {
			let slotContainer = this.getCurrentSlotContainer()
			let relativePosY = posY + slotContainer.scrollTop - this.scheduleTableHeaderHeight - 12

            let position = relativePosY / this.cellHeight
            let currentHour = this.workTime.range()[Math.trunc(position)]
            let currentMinute = Math.round((position % 1) * 60);

			if (currentHour == undefined || currentMinute < 0)
                return
            if (currentHour == this.workTime.end.getHours() && 
				currentMinute == this.workTime.end.getMinutes())
                return


            if (currentMinute > 60) {
                currentHour += 1;
                currentMinute = 0;
            }

			this.checkValidTime(futureColumnDate, currentHour, currentMinute)

			let roundedTime = this.roundTime(currentHour, currentMinute)
			
			this.currentHour = roundedTime.hour
            this.currentMinute = roundedTime.minute
        },

		roundTime(currentHour, currentMinute){
			let movementStep = CONFIG["movement-step"]
			let roundedMinute = Math.round(currentMinute / movementStep) * movementStep
			if (roundedMinute == 60){
				currentHour += 1
				roundedMinute = 0
			}
			return {hour: currentHour, minute: roundedMinute}
		},

		onInvalidTime(data){
			if (!data)
				return
			const {reason, period} = data
			if (!period || reason != 'is-intersect')
				return 
			const currentInvalidTimeDate = new Date(period.start.date)
			
			// Send request to calc nearest free place
			const columnTitle = `column-${currentInvalidTimeDate.toLocaleDateString()}`
			eventBroker.$emit(columnTitle, 'define-nearest-free-place', period)
			
			// If last sended columns is not equal to current
			if (this.lastInvalidTimeDate && !isEqualDate(this.lastInvalidTimeDate, currentInvalidTimeDate)) {
				const lastDateColumnTitle = `column-${this.lastInvalidTimeDate.toLocaleDateString()}`
				eventBroker.$emit(lastDateColumnTitle, 'define-nearest-free-place', null)
			}

			this.lastInvalidTimeDate = new Date(period.start.date)
		},

		onValidTime(){
			if (!this.lastInvalidTimeDate)
				return
			const lastDateColumnTitle = `column-${this.lastInvalidTimeDate.toLocaleDateString()}`
			eventBroker.$emit(lastDateColumnTitle, 'define-nearest-free-place', null)
			this.lastInvalidTimeDate = null
		},

		scrollContainer(posY, isMovingDown){
			let relativeY = Math.round(posY - this.scheduleTableHeaderHeight - 12)

			let scrollBuffer = 0
			let contHeight = this.slotContainerSizes["height"]
			let contScroll = this.slotContainerSizes["scroll-area"]
	
			let tableTopLimiter = (target) => target.scrollTop > 0 && !isMovingDown
			let tableBotLimiter = (target) => {
				return Math.floor(contScroll - contHeight - target.scrollTop - this.cellHeight) > 0 
					&& isMovingDown && !this.isDeletePreparation
			}

			/* 
				Interrupt the table scrolling if 
				1. The slot is in the non-border area
				2. The slot is in the top border area and the table is scrolled to the top
				3. The slot is in the bottom border area and the table is scrolled to the bottom
			*/  
			
			let isNonBorderArea = posY - this.cellHeight > scrollBuffer  &&
								posY - contHeight + this.cellHeight < scrollBuffer

			let isTopBorderArea = relativeY - this.cellHeight <= scrollBuffer 
								&& !tableTopLimiter(this.slotContainer) && !isMovingDown

			let isBotBorderArea = relativeY - contHeight + this.cellHeight > scrollBuffer 
								&& !tableBotLimiter(this.slotContainer) && isMovingDown


			if (isNonBorderArea || isTopBorderArea || isBotBorderArea) { 
				if (this.scrollInterval){
					clearInterval(this.scrollInterval)
					this.scrollInterval = null
				}
				this.scrollSpeed = null
				return
			}

			/*
				Update the scroll speed if the slot is in the border area
			*/


			if (relativeY - this.cellHeight <= scrollBuffer)
				this.scrollSpeed = Math.floor(Math.abs(relativeY - this.cellHeight) / this.scrollStep) * -1
			else if (relativeY - contHeight + this.cellHeight + 50 >= scrollBuffer)
				this.scrollSpeed = Math.floor(Math.abs(relativeY - contHeight + this.cellHeight + 50) / this.scrollStep) + 1

			if (this.scrollInterval)
				return
			
			let scrollCondition 

			// Scroll to the top
			if (relativeY - this.cellHeight <= scrollBuffer && this.slotContainer.scrollTop != 0) {
				this.scrollSpeed *= -1
				scrollCondition = (target) => tableTopLimiter(target)

			// Scroll to the bottom
			} else if (relativeY - contHeight + this.cellHeight * 3 >= scrollBuffer) {
				scrollCondition = (target) => tableBotLimiter(target)
			} else {
				return 
			}

			// Update the position y and slot time
			let onScroll = (difference) => {
				this.setTimeForPosition(this.posY - 16.5, this.futureColumn)
				this.sendMoveEvent(this.posX, this.posY)
			}

			// Start scrolling using interval
			this.scrollInterval = scrollByCondition(this.slotContainer, () => this.scrollSpeed, scrollCondition, {
				onScroll,
				delay: 15,
			})			
		},

		handleEnd(event){

			if (!this.slotContainer)
				return 

			if (!this.isDeletePreparation && this.timeIsWrong) {
				const date = new Date(this.futureColumn)
				date.setHours(this.currentHour, this.currentMinute, 0, 0)
				const period = new TimePeriod(date, -1, 60)
				const nearestFreePlace = this.slotCollection.nearestFreePlace(period)
				if (nearestFreePlace) {
					this.$emit('on-drag-end', {
						minute: nearestFreePlace.start.date.getMinutes(),
						hour: nearestFreePlace.start.date.getHours(),
						date: nearestFreePlace.start.date,
					})
				} else {
					this.$emit('on-drag-end', null)
				}
				this.onValidTime()
			} else if (!this.isDeletePreparation){
				this.$emit('on-drag-end', {
					minute: this.currentMinute,
					hour: this.currentHour,
					date: this.futureColumn,
				})
			} else {
				this.$emit('on-drag-end', null)
			}

			if (this.scrollInterval) {
				clearInterval(this.scrollInterval)
				this.scrollInterval = null
			}

			this.colorizeFutureColumn(null)

			globalBus.$emit('static-menu', 'update-visible', {visible: true})
			
			this.$store.dispatch('set-test-event', null)

			this.dragging = null
			this.posX = 0
			this.futureColumn = null
			this.lastInvalidTimeDate = null
			this.columnsX = null
			this.posY = 0
			this.columnWidth = null
			if ('PointerEvent' in window) {
				this.$el.removeEventListener('pointermove', this.handleMove)
			} else {
				this.$el.removeEventListener('mousemove', this.handleMove)
			}

		},
	},
};
</script>

<style scoped>
#create-slot{

	position: absolute;

	background: white;
	border: 1.5px solid #0075ff;
	color: #0075ff;
	padding: 0 5px;

	max-width: 200px;
	border-radius: 4px;
	font-size: 10px;
	user-select: none;
}

#create-slot.invalid{
	border-color: #f14668;
	color: #f14668;
}

.draggable {
	z-index: 1001;
}

.current-time-line{
	border-top: 1.5px dashed #0075ff;
	position: absolute;
	top: 0;
}

.current-time-line.invalid{
	border-color: #f14668;
	color: #f14668;
}

.time-string{
	width: fit-content;
	background: white;
	width: 30px;
	text-align: end;
	padding-right: 4px;
}

.nearest-time{ 
	position: absolute;
	left: 2px;
	top: -24px;
	font-size: 10px;
	z-index: 10;
	background: white;
	border-radius: 4px;
	padding: 2px 4px;
	border: 1px solid #0075ff;
	color: #0075ff;
	width: fit-content;
}

</style>