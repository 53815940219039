<template>
	<div class="schedule-table-frame">

		<!-- Date -->
		<!-- <div class="table-header" v-if="!disableHeader">
			<div class="table-header__left-side">
				<div class="table-date">{{ dateTitle }}</div>
				<div v-if="currentSlotCollection.length()">|</div>
				<div v-if="currentSlotCollection.length()" class="table-slot-count">
					{{ currentSlotCollection.length() }} slots
				</div>
				<div v-if="currentSlotCollection.questionLength()">|</div>
				<div v-if="currentSlotCollection.questionLength()" class="table-slot-count">
					{{ currentSlotCollection.questionLength() }} new slots
				</div>
			</div>
			<div class="table-header__right-side" v-if="isEditable">
				<div class="table-manage-slots">
					Manage slots
				</div>
				<b-switch size="is-small"
				type="is-info" 
				v-model="isTableView" 
				@input="onSwitchToggle">
				</b-switch>
			</div>
		</div> -->


		<!-- Slots using the table view -->

		<div class="slot-container table" v-bind:style="{
			height : slotContHeight(),
		}">
			<CalendarV2
				:selectedDate="date"
				:workTime="workTime"
				:calendarUid="calendarUid"
				:columnCount="daysCount"
				:slotCollection="slotCollection"
				:cellHeight="editTableCellHeight"
				:isEditable="isEditable"
				:fromDate="currentMonth.from"
				:toDate="currentMonth.to"
				@on-select-date="onSelectDate"
			/>
		</div>


		<!-- Slots using the list view -->
<!-- 
		<div class="slot-container" 
		v-show="!isEditable"
		v-bind:style="{
			height : slotContHeight(),
		}"> -->

			<!-- Support messages -->

			<!-- <div class="message support" 
			v-if="supportMessage">
				Place your new slot here
			</div>

			<div class="message"
			v-if="slotsWithForms.length == 0 && !supportMessage">
				<div class="message-title">List is empty</div>
				<div class="message-text" v-if="isEditable">
					To create a new slot just try to replace the plus button on the bottom menu
				</div>
				<div class="message-text" v-else>
					There are no slots for this day
				</div>
			</div>

			<div class="slot" v-for="slot, index in slotsWithForms" :key="`list-slot-${index}`">
				
				<div class="insert-label top" v-show="insertLabel == index">
					<div class="icon-container">
						<span class="iconify" 
						data-icon="material-symbols:add-rounded" 
						data-width="16" 
						data-height="16"
						style="color:white">
						</span>
					</div>
				</div> -->

				<!-- Ready slots -->
				
				<!-- <ScheduleSlot v-if="slot.index != undefined" :eventSlot="slot"/> -->

				<!-- New slots -->

				<!-- <ScheduleSlotForm v-else 
					:questionSlot="slot"
					:slotCollection="currentSlotCollection"
				/> -->

				<!-- <div class="insert-label bot" 
					v-show="insertLabel == slotsWithForms.length && 
						index == slotsWithForms.length - 1">
					<div class="icon-container">
						<span class="iconify" 
						data-icon="material-symbols:add-rounded" 
						data-width="16" 
						data-height="16"
						style="color:white">
						</span>
					</div>
				</div>
			</div>
		</div> -->

	</div>
</template>

<script>
import YYYYMMDD, { dateIsPast, getTimeString, isEqualDate } from '../../scripts/date';
import Slot from '../../scripts/slot';
import { enShortMonths } from '../../scripts/months';
import {bus} from './EventBus.js'


import ScheduleSlot from './ScheduleSlot.vue';
import ScheduleSlotForm from './ScheduleSlotForm.vue';
import ScheduleTableFrameGrid from '../calendar-v2/Calendar_v2.vue';
import CalendarHeader from '../../scripts/calendarHeader.js';
import CalendarV2 from '../calendar-v2/Calendar_v2.vue';
import CalendarWorkTime from '../../scripts/calendarWorkTime';
import SlotCollection from '../../scripts/slotCollection';
import Calendar from '../../scripts/calendar.js';
import CalendarMonth from '../../scripts/calendarMonth.js';


export default {
	name: 'ScheduleTableFrame',
	emits: [
		'on-select-date',
	],
	components: {
		ScheduleTableFrameGrid,
		ScheduleSlotForm,
		ScheduleSlot,
		CalendarV2,
	},
	props: {
		date: {
			type: Date,
			required: true,
		},
		calendar: {
			type: Calendar,
			required: true,
		},
		currentMonth: {
			type: CalendarMonth,
			required: true,
		},
		workTime: {
			type: CalendarWorkTime,
			required: true,
		},
		editTableCellHeight: {
			type: Number,
		},
		isEditable: {
			type: Boolean,
			default: true,
		},
		disableHeader: {
			type: Boolean,
			default: false,
		},
		daysCount: {
			type: Number,
			required: true,
		},
	},
	computed: {
		ceilSize(){
			return this.$store.getters.tableCeilSize
		},
		dateTitle(){
			let now = new Date()
			let date = this.date.getDate()
			let month = enShortMonths[this.date.getMonth()]
			
			if (now.getFullYear() == this.date.getFullYear())
				return `${date} ${month}`

			return `${date} ${month} ${this.date.getFullYear()}`
		},
		calendarUid(){
			return this.$route.params.calendarUid
		},
		currentNewSlots() {
			return this.newSlots.filter(slot => {
				return isEqualDate(this.date, slot.period.start.date)
			})
		},
		slotCollection(){
			return this.$store.getters.calendarSlotCollection
		}
	},

	data() {
		return {
			newSlots: [],
			insertLabel: null,
			supportMessage: null,
			addedSlots: 0,
		};
	},

	methods: {

		openManageSlots() {
			this.$router.push({
				name: "calendar-schedule",
				params: {
					calendarUid: this.$route.params.calendarUid,
				},
				query: {
					dt: YYYYMMDD(this.date),
					w: 7,
				},
			})
		},
		slotStartTime(slot){
			return getTimeString(slot.period.start.date)
		},

		// showInsertLabel({insertIndex}){

		// 	let now = new Date()
		// 	let isToday = isEqualDate(now, this.date)
			
		// 	if (dateIsPast(this.date) && !isToday) {
		// 		return
		// 	}
		// 	if (this.slotsWithForms.length == 0 || insertIndex == null || insertIndex == undefined)
		// 		return
			
		// 	let insertLabel
		// 	if (insertIndex < this.slotsWithForms.length) {
		// 		insertLabel = insertIndex
		// 	} else {
		// 		insertLabel = this.slotsWithForms.length
		// 	}
		// 	this.insertLabel = insertLabel
		// },
	
		// showSupportMessage(value){
		// 	if (this.slotsWithForms.length > 0)
		// 		return

		// 	this.supportMessage = value
		// },
		onSwitchToggle(value){

			let query = {...this.$route.query}

			if (value) {
				query["tb"] = 1
			} else {
				delete query["tb"]
			}

			this.$router.replace({
				name: this.$route.name,
				params: this.$route.params,
				query: query
			}).catch(() => {})
		},
		slotContHeight(){
			const collapseStep = Number(localStorage.getItem('calendar-collapse-step')) ?? 1
			let calendar = document.querySelector('#schedule-calendar')
			if (!calendar || collapseStep == 2)
				return '100%'

			const calendarHeight = calendar.getBoundingClientRect().height
			
			const header = document.querySelector('#header')
			const headerHeight = header ? 
				header.getBoundingClientRect().height : 0

			return `calc(100dvh - ${headerHeight}px  - ${calendarHeight}px)`
		},
		onSelectDate(date){
			this.$emit('on-select-date', date)
		},
	},
};
</script>

<style scoped>

.schedule-table-frame{
	overflow-y: hidden;
	height: inherit;
	transition: .3s height;
	position: relative;
	display: flex;
	flex-direction: column
}

.ceil-container{
	display: flex;
	flex-direction: column
}

.ceil:not(:first-child){
	border-top: 1px solid lightgrey;
}

.ceil{
	position: relative;
}
.ceil-hour{
	font-size: 12px;
    position: absolute;
    top: -10px;
    background: white;
    padding-right: 6px;
    padding-left: 4px;
    text-align: center;
    color: grey;
    z-index: 1;
}
.line{
    margin-right: 4px;
    width: 8px;
    float: right;
}

.line:not(:first-child) {
    border-bottom: 1px solid lightgray;
}

.ceil-lines{
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 8px;
	height: inherit;
	position: absolute;
	top: 0;
	left: 0;
}

.table-header{
	font-size: 12px;
	background: white;
	padding: 4px 15px 4px 25px;
	font-weight: 500;
	display: flex;
	justify-content: space-between;
}

.table-header__left-side{
	display: flex;
	color: #9f9f9f;
	gap: 10px;
}

.table-header__right-side{
	display: flex;
	gap: 10px;
}

.slot-container{
	padding-left: 25px;
	padding-right: 15px;
	position: relative;
	box-sizing: border-box;
	height: 100%;
}

.slot-container:not(.table){
	overflow-y: auto;
}

.slot-container.table{
	padding-left: 0;
	padding-right: 0;
	/* box-sizing: border-box; */
}

.message {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	background: inherit;
	position: absolute;
	width: calc(100% - 25px - 15px);
	height: inherit;
	top: 0;
	left: 25px;
}

.message > .message-title {
	font-weight: 500;
	font-size: 20px;
}

.message > .message-text{
	font-size: 14px;
	color: #9f9f9f;
	text-align: center;

}

.message.support {
	border-radius: 10px;
	border: 4px dashed #f1f1f1;
	animation: fade 0.2s ease-in-out;
	color: #9f9f9f;
	font-weight: 500;
}

.slot{
	padding-bottom: 15px;
	padding-top: 10px;
	position: relative;
}

.slot:not(:last-child){
	border-bottom: 1px solid #f1f1f1;
}

.insert-label {
	height: 1px;
	width: 100%;
	/* box-shadow: 0 0 0 1px #adcdf3; */
	background: #167df0;
	position: absolute;
	border-radius: 1px;
	left: 0;
	animation: fade 0.2s ease-in-out;
	z-index: 3;
}

.insert-label.top {
	top: 0px;
}

.insert-label.bot {
	bottom: 0px;
}

.insert-label > .icon-container {
	width: 20px;
	height: 15px;
	background: #167df0;
	position: absolute;
	top: 0;
	left: -1px;
	z-index: 4;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 2px 0 2px 2px;
}

.top-border{
	border-top: 1px solid gray;
	margin-left: 30px;
}
</style>