<template>
	<div class="selected-border" v-bind:style="{
		...styles
	}"></div>
</template>

<script>
import { isEqualDate, nextDate } from '../../../scripts/date';

export default {
	name: 'WeekSelectedDays',
	props: {
		selectedDate: {
			type: Date,
			required: true,
		},
		week: {
			type: Array,
			required: true,
		},
		daysCount: {
			type: Number,
			default: 1,
		},
		itemsContainer: {
			type: HTMLElement,
		},
		collapseStep: {
			type: Number,
		}
	},
	computed: {
		weekItem(){
			return document.querySelector('.schedule-date')
		},
		styles(){
			this.isMounted
			this.selectedDate
			this.itemsContainer
			this.weekItem
			this.daysCount
			this.weekItemWidth
			
			const result = {}
			if (!this.isMounted) {
				return {
					display: 'none'
				}
			}

			const left = this.calcLeft()
			const width = this.calcWidth()

			result["left"] = left != null ? 
				`${left}px` : undefined
			result["width"] = width != null ?
				`${width}px` : undefined
			if (!result["left"] || !result["width"])
				result["display"] = 'none'
			return result
		}
	},

	data() {
		return {
			weekItemWidth: 0,
			updateInterval: null,
			isMounted: false,
		}
	},

	mounted() {
		this.isMounted = true
		this.updateInterval = setInterval(() => {
			const width = this.getItemWidth()
			if (width != null && width > 10) {
				this.weekItemWidth = width
				clearInterval(this.updateInterval)
			}
		})
	},

	beforeDestroy() {
		if (this.updateInterval) {
			clearInterval(this.updateInterval)
		}
	},

	methods:{

		getItemWidth(){
			if (!this.itemsContainer)
				return null

			const scheduleDates = Array.from(this.itemsContainer.querySelectorAll('.schedule-date'))
			if (scheduleDates.length == 0)
				return null
			const visibleScheduleDate = scheduleDates.find(elem => elem.style.display != 'none')
			if (!visibleScheduleDate)
				return null
			const rect = visibleScheduleDate.getBoundingClientRect()
			return rect.width
		},

		getPosLeft(elem) {
			const leftContainer = this.itemsContainer.getBoundingClientRect().left
			const leftElem = elem.getBoundingClientRect().left
			return leftElem - leftContainer
		},
		getDateChild(date){
			const datesElements = Array.from(this.itemsContainer.querySelectorAll(`.schedule-date`))
			if (!datesElements || datesElements.length == 0)
				return null
			const dateElem = datesElements.find(elem => {
				return elem.id == `date-${date.toLocaleDateString()}`
			})
			return dateElem
		},

		calcLeft(){
			if (!this.itemsContainer || !this.weekItemWidth)
				return null

			const difference = this.daysCount == 1 ? 
				0 : this.daysCount - 1
			const selectedDateEnd =  nextDate(this.selectedDate, difference)
			const rangeStartElem = this.getDateChild(this.selectedDate)	
			const rangeEndElem = this.getDateChild(selectedDateEnd)
			if (!rangeStartElem || !rangeEndElem)
				return null
			return this.getPosLeft(rangeStartElem)
		},
		calcWidth(){
			if (!this.itemsContainer || !this.weekItemWidth)
				return null

			const difference = this.daysCount == 1 ? 
				0 : this.daysCount - 1
			const selectedDateEnd =  nextDate(this.selectedDate, difference)
			const rangeStartElem = this.getDateChild(this.selectedDate)	
			const rangeEndElem = this.getDateChild(selectedDateEnd)
			if (!rangeStartElem || !rangeEndElem)
				return null
			const startLeft = this.getPosLeft(rangeStartElem)
			const endLeft = this.getPosLeft(rangeEndElem)
		
			return endLeft - startLeft + this.weekItemWidth
		}
	}
};
</script>

<style scoped>
.selected-border{
	position: absolute;
	top: 2px;
	height: calc(100% - 4px);
	border: 1px solid  #0075ff;
	box-shadow: 0 0 0 1.5px #adcdf3;
	border-radius: 4px;

	transition: all .3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

</style>