<template>
	<div class="schedule-week">
		<div class="week-number">
			<div class="value">{{ week.getWeekNumber() }}</div>
		</div>
		<div class="week-content" ref="week-content">

			<DateItem 
				v-for="date in dates" 
				:key="`date-${_uid}-${date.toLocaleDateString()}`"
				:date="date"
				:isCurrentMonth="checkCurrentMonth(date)"
				:isSelected="isSelected(date)"
				:collapseStep="collapseStep"
				:selector="selectHandler"
			/>

			
			<WeekSelectedDays v-if="!disableSelection && isContainSelectedDate"
				:collapseStep="collapseStep"
				:selectedDate="selectedDate"
				:week="dates"
				:daysCount="daysCount"
				:itemsContainer="itemsContainer"
			/>

		</div>
	</div>
</template>

<script>
import { isEqualDate, smartDateSelect } from '../../../scripts/date';
import DateItem from './DateItem.vue';
import WeekSelectedDays from './WeekSelectedDays.vue';

export default {
	name: 'Week',
	components: { DateItem, WeekSelectedDays },
	model: {
		prop: 'selectedDate',
		event: 'update:selectedDate'
	},
	props: {
		week: {
			type: Object,
			required: true,
		},
		selectedDate: {
			type: Date,
			required: true,
		},
		checkCurrentMonth: {
			type: Function,
			default: (date) => {return true},
		},
		collapseStep: {
			type: Number,
			default: 1,
		},
		disableSelection: {
			type: Boolean,
			default: false
		},
		daysCount: {
			type: Number
		},
	},

	data(){
		return {
			itemsContainer : null,
		}
	},

	computed: {
		dates(){
			return this.week.range()
		},
		isContainSelectedDate(){
			return this.dates.some(date => isEqualDate(date, this.selectedDate))
		}
	},

	mounted(){
		this.itemsContainer = this.$refs["week-content"]
	},

	beforeDestroy() {
		this.itemsContainer = null
	},

	methods: {

		selectHandler(date){
			if (this.disableSelection)
				return
		
			const newDate = smartDateSelect(date, this.daysCount)

			this.$emit('update:selectedDate', newDate)
		},

		isSelected(date){
			if (this.disableSelection)
				return false
			return isEqualDate(date, this.selectedDate)
		},


	},
};
</script>

<style  scoped>
.schedule-week{
	display: flex;
	flex-direction: row;
	flex: 1;
	min-height: 58px;
	gap: 2px;
	height: 100%;
	padding-right: 16px;
}

.week-number {
	border-right: 1.5px solid #eaeaea;
	padding-top: 2px;
	padding-right: 2px;
	text-align: center;
	color: rgb(170, 170, 170);
	font-size: 10px;
	font-weight: 500;
	width: 30px;
	height: 100%;
}

.week-content{
	display: flex;
	touch-action: none;
	position: relative;
	flex-direction: row;
	gap: 4px;
	width: 100%;
	padding-bottom: 2px;
	overflow-x: hidden;
	padding: 2px;
	box-sizing: border-box;
	overflow: hidden;
}
</style>