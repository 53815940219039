<template>
	<div class="calendar-v2-table" ref="table">

		<div class="holder"></div>
		<slot name="header"></slot>
		<slot name="hours"></slot>

		<div class="calendar-v2-table__column-section">

			<slot name="columns"></slot>

		</div>
	</div>

</template>

<script>
import { dateDifferenceInDays, isEqualDate } from '../../scripts/date';
import { makeDraggable, makeUndraggable } from '../../scripts/draggable'
import { eventToCoordinates } from '../../scripts/slotCardPosition';

export default {
	name: 'ScrollableTable',

	emits: [
		'on-select-date',
	],

	props: {
		selectedDate: {
			type: Date,
			required: true,
		},
		columns: {
			type: Array,
			required: true,
		},
		columnWidth: {
			type: Number,
			required: true,
		},
	},

	computed: {
		tableHeader() {
			return this.$refs['table']
		},
	},

	data() {
		return {
			originX: 0,
			selectedIndex: 0,
			isTouched: false,
		};
	},

	watch: {
		columnWidth(){
			this.roundScrollX(false)
		},
		selectedDate(){
			this.selectedIndex = this.calcSelectedIndex()
			this.roundScrollX()
		}
	},	

	mounted() {
		makeDraggable(this.tableHeader, {
			start: this.onTouchStart,
			move: this.onTouchMove,
		})
		this.tableHeader.addEventListener('scrollend', this.onScrollEnd)
		this.roundScrollX(false)
		this.selectedIndex = this.calcSelectedIndex()
	},

	beforeDestroy() {
		makeUndraggable(this.tableHeader, {
			start: this.onTouchStart,
			move: this.onTouchMove,
		})
		this.tableHeader.removeEventListener('scrollend', this.onScrollEnd)
	},

	methods: {
		onTouchStart(ev) {
			this.isTouched = true
			this.originX = this.$refs['table'].scrollLeft
			this.$emit('on-scroll-start', ev)
		},
		
		onTouchMove(ev) {
			this.$emit('on-scroll-move', ev)
		},
		
		onScrollEnd(ev){
			if (!this.isTouched) 
				return
			this.isTouched = false
			const newScrollX = this.$refs['table'].scrollLeft
			const deltaX = Math.round((newScrollX - this.originX) / this.columnWidth)

			if (deltaX != 0) {
				const newDate = new Date(this.selectedDate)
				newDate.setDate(newDate.getDate() + deltaX)
				this.$emit('on-select-date', newDate)				
			} else {
				this.roundScrollX()
			}
			this.originX = newScrollX
			
			this.$emit('on-scroll-end', ev)
		},

		roundScrollX(smooth=true){
			const scrollValue = this.columnWidth * this.selectedIndex
			this.$refs['table'].scrollTo({
				left: scrollValue,
				behavior: smooth ? 'smooth' : 'auto',
			})
		},

		calcSelectedIndex(){
			const selectedDate = this.selectedDate
			const columnItems = this.columns
			const index = columnItems.findIndex(column => isEqualDate(column.date, selectedDate))
			return index
		}
	},
};
</script>

<style scoped>

.calendar-v2-table__column-section {
	display: flex;
	flex-direction: row;
	width: 100%;
	height: 100%;
}

.holder{
	background:white;
	position: sticky;
	top: 0;
	left: 0;
	z-index: 5;
	width: 32px;
	height: 20px;
}

.calendar-v2-table {
	
	overflow: auto;
	padding-right: 16px;
	position: relative;
	padding-bottom: 50px;
	width:  100%;
	height: 100%;

	display: grid;
	grid-template-columns: 32px calc(100% - 32px + 16px);
	grid-template-rows: 20px calc(100% - 20px);
}
.calendar-v2.no-scroll {
  	overflow: hidden;
}

</style>